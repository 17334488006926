<template>
  <div class="examPack">
    <div v-if="roomList && roomList.length > 0" class="list">
      <div v-for="(item, index) in roomList" :key="index" class="item" @click="tabId = item.id">
        <img class="item-image" src="@/assets/img/partner/pager.png">
        <div class="item-content" :class="item.seeNum && !isPurchase ? 'shiting-title' : ''">
          <div class="item-title ellipsis">
            {{ item.sjName }}
          </div>
          <div class="item-count ellipsis">共{{ item.subjectNum }}题</div>
        </div>

        <div v-if="item.paperPrice > 0 && item.status == 2" class="keep-learn" @click.stop="goOrder(item, 12)">
          立即购买
        </div>
        <div v-if="item.status == 1 || !item.paperPrice || item.paperPrice == 0" class="keep-learn keep-check"
          @click="checkClick(item)">
          {{ item.seeNum && (parseInt(item.seeNum) > 0) ? '试做' : '开始答题' }}
        </div>
      </div>
    </div>
    <div v-if="!roomList || roomList.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { Know } from '@/api/know'
const know = new Know()
import { getInfo, getToken } from '@/api/cookies'
export default {
  props: {
    isPurchase: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    type: {
      type: Number /* 1 直播 2录播 3音频 4 图文 5面授 */
    }
  },
  data() {
    return { userInfo: null, roomList: [], dialogVisible: false }
  },
  created() {
    this.userInfo = getInfo()
    if (!this.id) {
      return
    }
    know
      .getPaperByCourse(
        undefined,
        this.type,
        this.id ? this.id : undefined,
        undefined,
        3
      )
      .then((res) => {
        this.roomList = res.data.array
      })
  },
  methods: {
    /* 下载 */
    checkClick(item) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.isPurchase || item.seeNum) {
        let type = this.type
        switch (this.type) {
          case 1:
            type = 2
            break
          case 2:
            type = 3
            break
          case 3:
            type = 7
            break
          case 4:
            type = 8
            break
          case 5:
            type = 10
            break
          default:
            break
        }
        this.$router.push(
          `/quesTion/examDetails?title=${item.sjName}&paperId=${item.cContactid}&examType=5&type=${type}&courseId=${this.id}&IsPurchase=${this.isPurchase}`
        )
      } else {
        this.$notify({
          title: '错误',
          message: '未购买，无法学习, 请先去购买',
          type: 'error',
          duration: 2000
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
/* 试卷列表 */
.examPack {
  .list {
    width: 100%;
    padding: 20px;
    background: #ffffff;

    .item {
      position: relative;
      width: 811px;
      height: 70px;
      background: #f9f9f9;
      border-radius: 6px 6px 6px 6px;
      margin-bottom: 10px;
      opacity: 1;
      display: flex;
      align-items: center;
      padding-left: 20px;

      .item-image {
        width: 33.28px;
        height: 36.48px;
        flex-shrink: 0;
        background: #f7f8f9;
        margin-right: 12px;
      }

      .item-content {
        flex: 1;

        .item-title {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          max-width: calc(100% - 80px);
        }

        .item-count {
          margin-top: 5px;
          height: 14px;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }

      .keep-learn {
        flex-shrink: 0;
        width: 108px;
        height: 36px;
        border-radius: 18px 18px 18px 18px;
        background-color: #ffffff;
        opacity: 1;
        text-align: center;
        border: 1px solid #2586f5;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #2586f5;
        line-height: 36px;
        margin-right: 39px;
      }
    }
  }

  /* 试kan */
  .audition {
    width: 48px;
    height: 20px;
    background: #ffedeb;
    border-radius: 2px 2px 2px 2px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff5e51;
    line-height: 20px;
    margin-left: 8px;
    text-align: center;
    flex-shrink: 0;
  }
}
</style>
